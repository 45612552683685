import React from "react"
import Container from "../../components/container"
import Head from "../../components/head"
import NodeGarden from "../../components/new/nodeGarden/nodeGarden"
import Grid from "../../components/new/grid/grid"
import Layout from "../../components/new/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import Paragraph from "../../components/new/paragraph/paragraph"
import HubspotContactFormPartner from "../../components/hubspotContactFormPartner"


export default function GetPricing () {
	const data = useStaticQuery(graphql`
		query {
			allContentfulCustomer {
				edges {
					node {
						logo
						title
						featured
						url
						slug
					}
				}
			}
		}
	`)
    return (
		<Layout navbar='darkerblue'>
			<Head title="Transform Your Marketplace Operations Now" description="Ensure the future of your marketplace by booking a demo of the Onport Platform now."/>
			<Container style={{classes: "container-fluid bg-contact-lg py-5" }}>
				<Container style={{classes: "container position-relative" }}>
					<div className="row mt-5 pt-5 align-items-start">
						<div className="col-12 col-md-6 mt-4">
						<h2 className="text-white" style={{fontWeight:'bold'}}>Book a discovery call to find the right pricing package to automate your Marketplace  operations with future-proof technology.</h2>
                        	<Paragraph text='<br>The discovery call will focus on:<br><br>' style='light w-75 font-weight-bold l'/>
                        	<Paragraph text='· Identifying the current pain points in your ecommerce operation<br><br>· Understanding whether Onport is the right solution for your needs<br><br>· Defining which pricing package is best suited for your online business<br><br>We can support you at any stage of your journey with next-generation composable technology that allows you to manage your ecommerce marketplace operations from one single platform.' style='light w-75 l'/>
							<div className='mt-5 text-white'style={{background: 'rgba(255, 255, 255, 0.1)', padding: '1rem', borderRadius:'8px' }}>
								<p className='quote mb-4' style={{fontStyle: 'italic', fontSize: '1.2rem'}}>It would take quite a decent size team of developers and would still take a lot of time to build in-house. If you work with a small team it will take probably over a year, to actually build something that may look a little bit like Onport, but not even close.</p>
								<div className="d-flex align-items-center">
									<img src='https://www.emerce.nl/content/uploads/2021/06/Edwin-Klasens.jpeg' style={{height: '60px', width: '60px', borderRadius: '100%', objectFit:'cover', filter: 'grayscale(100%)'}}/>
									<div className="ml-3">
										<p style={{fontWeight:'900', fontSize: '1rem'}}>Edwin Klasens</p>
										<p style={{fontSize: '1rem'}}>Integrations Specialist</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 bg-lol2 pt-4">
								<h2 className="text-white pl-4" style={{fontWeight:'bold'}}>Take the first step today</h2>
								<HubspotContactFormPartner id="a1eda0e8-5988-4430-a25f-21f7b0c55874"/>
						</div>
					</div>
					<Grid style='grid-4 mt-5 py-4'>
								{data.allContentfulCustomer.edges.map((edge) => {
									if (edge.node.slug === "and-open" || edge.node.slug === "stadium-goods" || edge.node.slug === "terminal-x" || edge.node.slug === "maavee") {
										return(
											<a href={edge.node.url} target="_blank">
												<img src={edge.node.logo} alt={edge.node.title} style={{opacity: '0.6', maxHeight:'160px',maxWidth:'200px', filter:'invert(100%) saturate(0)'}}/>
											</a>
										)
									}
								})}
					</Grid>
				</Container>	
			</Container>
		</Layout>
    )
}
